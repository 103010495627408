import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

 const ROOT = 'https://touhfati.com/api/';
//const ROOT = 'http://127.0.0.1:8000/api/';


const getData = `${ROOT}getProduit`;
const sendData = `${ROOT}storeProduitInPanier`;

@Injectable({
  providedIn: 'root'
})


export class ApiService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json;',
  });
  headers2 = new HttpHeaders({
    'Content-Type': 'multipart/form-data;application/json',
  });

  constructor(
    private http: HttpClient,
  ) { }

  getData(data) {
    console.log('data', data);
    return this.http.post(getData, data, { headers: this.headers })
      .pipe(map(results => {
        return results;
      }
      ));
  }

  
  sendtData(data) {
    return this.http.post(sendData, JSON.stringify(data), { headers: this.headers2 })
      .pipe(map(results => {
        return results;
      }
      ));
  }
}
