import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { EditorComponent } from './editor/editor.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: 'editor/:user/:produit', component: EditorComponent },
  { path: '404', component: NotFoundComponent },
  { path: '', redirectTo: '404', pathMatch: 'full' }, // redirect to `first-component`
  { path: '**', redirectTo: '404' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
