<div class="container">
    <div class="row">
        <div class="col-xl-12">
            <div class="row pt-1">
                <div class="col-xl-4">
                    <img class="logo" src="https://touhfati.com/styleTouhfati/assets/images/logo_dark.png">
                    <!-- <img class="logo" src="http://127.0.0.1:8000/styleTouhfati/assets/images/logo_dark.png"> -->
                </div>
                <div class="col-xl-8 text-right align-self-end ">
                    <button data-toggle="tooltip" data-placement="bottom" title="Clean Canvas" type="button" class=" btn btn-primary btn-sm save  ml-1 " (click)="sendData();">
                          <i class="fa fa-save" aria-hidden="true"></i> Sauvgarder</button>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-xl-2 pr-0">
            <ul class="nav flex-column">
                <li class="nav-item " [ngClass]="{'active': current == 0}" (click)="switchTo(0)">
                    <i class="fa fa-tint fa-lg" aria-hidden="true"></i>
                    <h6> Couleurs</h6>
                </li>
                <li class="nav-item" [ngClass]="{'active': current == 1}" (click)="switchTo(1)">
                    <i class="fa fa-font fa-lg" aria-hidden="true"></i>
                    <h6> Text</h6>
                </li>
                <li class="nav-item" [ngClass]="{'active': current == 2}" (click)="switchTo(2)">
                    <i class="fa fa-heart fa-lg" aria-hidden="true"></i>
                    <h6> Designs</h6>
                </li>
                <li class="nav-item" [ngClass]="{'active': current == 3}" (click)="switchTo(3)">
                    <i class="fa fa-square fa-lg" aria-hidden="true"></i>
                    <h6> Figures</h6>
                </li>
                <li class="nav-item" [ngClass]="{'active': current == 4}" (click)="switchTo(4)">
                    <i class="fa fa-picture-o fa-lg" aria-hidden="true"></i>
                    <h6> Images</h6>
                </li>
            </ul>
        </div>
        <div class="col-xl-4  pb-0">
            <div class="card">
                <div class="card-header">Options</div>
                <div class="card-body text-center">
                    <div class="btn-group" role="group" aria-label="...">
                        <button data-toggle="tooltip" data-placement="bottom" title="Supprimer l'élément" type="button" class="btn btn-danger" [disabled]="!canvas.selected" (click)="removeSelected();">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                        <button data-toggle="tooltip" data-placement="bottom" title="Envoyer au fond" type="button" class=" option btn btn-dark" [disabled]="!canvas.selected" (click)="sendToBack();">
                      <i class="fa fa-level-down" aria-hidden="true"></i>
                  </button>
                        <button data-toggle="tooltip" data-placement="bottom" title="Envoyer au premier plan" type="button" class=" option btn btn-dark" [disabled]="!canvas.selected" (click)="bringToFront();">
                      <i class="fa fa-level-up" aria-hidden="true"></i>
                  </button>
                        <button data-toggle="tooltip" data-placement="bottom" title="Cloner" type="button" class=" option btn btn-dark" [disabled]="!canvas.selected || canvas.selected.type == 'group'" (click)="clone();">
                      <i class="fa fa-clone" aria-hidden="true"></i>
                  </button>
                        <button data-toggle="tooltip" data-placement="bottom" title="Désélectionner" type="button" class="option btn btn-dark" [disabled]="!canvas.selected" (click)="cleanSelect()">
                      <i class="fa fa-remove" aria-hidden="true"></i>
                  </button>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="current == 0">
                <div class="card-header">Couleurs</div>
                <div style="text-align: center;" class="card-body max-height">
                    <div *ngFor="let c of colors; let i=index">
                        <img class="images-item" (click)="changeColor(c , i)" [src]=c.facade_image>
                        <!-- <span class="ml-4"> {{c.color_name}}</span> -->
                    </div>
                </div>
            </div>

            <div class="card" *ngIf="current == 1">
                <div class="card-header">Ajouter un text</div>
                <div class="card-body">
                    <div class="input-group input-group-sm ">
                        <input type="text" class="form-control" [(ngModel)]="canvas.textString">
                        <div class="input-group-append">
                            <button id="add-text" data-toggle="tooltip" data-placement="bottom" title="Add text" class="btn btn-dark" (click)="addText()">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                              </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="current == 2">
                <div class="card-header">Ajouter un Design</div>
                <div class="card-body max-height">
                    <img *ngFor="let d of data.designs" class="design-item" (click)="getImgPolaroid($event)" [src]="d.image">
                </div>
            </div>

            <div class="card" *ngIf="current == 3">
                <div class="card-header">Ajouter figure</div>
                <div class="card-body text-center max-height">
                    <div class=" btn-group" role="group" aria-label="...">
                        <button type="button" class="btn btn-dark" (click)="addFigure('rectangle');">
                            <i class="fa fa-stop fa-lg" aria-hidden="true"></i>
                        </button>
                        <button type="button" class="btn btn-dark" (click)="addFigure('square');">
                            <i class="fa fa-square fa-lg" aria-hidden="true"></i>
                        </button>
                        <button type="button" class="btn btn-dark" (click)="addFigure('triangle');">
                            <i class="fa fa-play fa-lg" aria-hidden="true"></i>
                        </button>
                        <button type="button" class="btn btn-dark" (click)="addFigure('circle');">
                            <i class="fa fa-circle fa-lg" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="current == 4">
                <div class="card-header">Charger une image</div>
                <div class="card-body text-center">
                    <img id="testImage" *ngIf="canvas.url" class="images-item-upload" [src]="canvas.url" (click)="addImageOnCanvas(canvas.url);">
                    <input type="file" class="file" accept="image/x-png,image/gif,image/jpeg" (change)="readUrl($event);">
                    <br/>
                    <br/>
                    <div class="btn-group btn-group-justified" role="group" aria-label="...">
                        <div class="btn-group" role="group">
                            <button type="button" class="btn btn-outline-danger btn-sm" (click)="removeWhite(canvas.url);">
                              <i class="fa fa-times" aria-hidden="true"></i> Supprimez</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="canvas.selected">
                <div class="card-header ">Personnaliser</div>
                <div class="card-body personnaliser">
                    <div class="custom-item" *ngIf="canvas.selected  && canvas.selected.type == 'group'">Groupe sélectionné</div>
                    <div class="custom-item" *ngIf="!canvas.selected">Aucun élément sélectionné
                    </div>

                    <div class="custom-item" *ngIf="canvas.selected && canvas.selected.type != 'group'">
                        <div class="custom-item-title">Opacité</div>
                        <div class="custom-item-body">
                            <input type="range" [(ngModel)]="canvas.props.opacity" (change)="setOpacity()">{{canvas.props.opacity}}</div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor || canvas.selected && canvas.figureEditor">
                        <div class="custom-item-title">Remplissage</div>
                        <div class="custom-item-body">
                            <input type="text" class="form-control form-control-sm colorPicker" [cpPosition]="'bottom'" [(colorPicker)]="canvas.props.fill" [style.background]="canvas.props.fill" [value]="canvas.props.fill" (colorPickerChange)="setFill()">
                        </div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Famille de police</div>
                        <div class="custom-item-body">
                            <select [(ngModel)]="canvas.props.fontFamily" class="form-control form-control-sm" (change)="setFontFamily()">
                                <option value="arial">Arial</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Poppins">Poppins</option>
                                <option value="Open Sans">Open Sans</option>
                                <option value="Proxima Nova">Proxima Nova</option>
                                <option value="Avenir">Avenir</option>
                                <option value="Gotham">Gotham</option>
                                <option value="Univers">Univers</option>
                                <option value="Futura">Futura</option>
                                <option value="helvetica" selected>Helvetica</option>
                                <option value="verdana">Verdana</option>
                                <option value="courier">Courier</option>
                                <option value="Roboto">Roboto</option>
                                <option value="Open Sans">Open Sans</option>
                                <option value="Zilla Slab">Zilla Slab</option>
                                <option value="Lato">Lato</option>
                                <option value="Bellefair">Bellefair</option>
                                <option value="Fresca">Fresca</option>
                                <option value="Raleway">Raleway</option>
                                <option value="Open Sans Condensed">Open Sans Condensed</option>
                                <option value="Indie Flower">Indie Flower</option>
                                <option value="Josefin Sans">Josefin Sans</option>
                                <option value="Inconsolata">Inconsolata</option>
                                <option value="Pacifico">Pacifico</option>
                                <option value="Gloria Hallelujah">Gloria Hallelujah</option>
                          </select>
                        </div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Alignement du texte</div>
                        <div class="custom-item-body text-center">
                            <div class="btn-group btn-group-sm" role="group" aria-label="...">
                                <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'left' }" (click)="setTextAlign('left')">
                                  <i class="fa fa-align-left"></i>
                              </button>
                                <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'center' }" (click)="setTextAlign('center')">
                                  <i class="fa fa-align-center"></i>
                              </button>
                                <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'right' }" (click)="setTextAlign('right')">
                                  <i class="fa fa-align-right"></i>
                              </button>
                                <button type="button" class="btn btn-primary" [ngClass]="{'active': canvas.props.textAlign == 'justify' }" (click)="setTextAlign('justify')">
                                  <i class="fa fa-align-justify"></i>
                              </button>
                            </div>
                        </div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Style</div>
                        <div class="custom-item-body text-center">
                            <div class="btn-group btn-group-sm" role="group" aria-label="...">
                                <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': canvas.props.fontWeight }" (click)="setBold()">
                                  <i class="fa fa-bold"></i>
                              </button>
                                <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': canvas.props.fontStyle }" (click)="setFontStyle()">
                                  <i class="fa fa-italic"></i>
                              </button>
                                <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': hasTextDecoration('underline') }" (click)="setTextDecoration('underline')">
                                  <i class="fa fa-underline"></i>
                              </button>
                                <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': hasTextDecoration('overline') }" (click)="setTextDecoration('overline')">
                                  <i class="fa fa-underline fa-flip-vertical"></i>
                              </button>
                                <button type="button" class="btn btn-primary btn-sm" [ngClass]="{'active': hasTextDecoration('line-through') }" (click)="setTextDecoration('line-through')">
                                  <i class="fa fa-strikethrough"></i>
                              </button>
                            </div>
                        </div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Taille de police</div>
                        <div class="custom-item-body">
                            <input type="range" [(ngModel)]="canvas.props.fontSize" (change)="setFontSize()" step="1" min="1" max="120">{{canvas.props.fontSize}}</div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Hauteur de la ligne</div>
                        <div class="custom-item-body">
                            <input type="range" [(ngModel)]="canvas.props.lineHeight" (change)="setLineHeight()" step="0.1" min="0" max="10">{{canvas.props.lineHeight}}</div>
                    </div>
                    <div class="custom-item" *ngIf="canvas.selected && canvas.textEditor">
                        <div class="custom-item-title">Espacement des caractères</div>
                        <div class="custom-item-body">
                            <input type="range" [(ngModel)]="canvas.props.charSpacing" (change)="setCharSpacing()" step="10" min="-200" max="800">{{canvas.props.charSpacing}}</div>
                    </div>
                </div>
            </div>


        </div>
        <div class="col-xl-6 ">
            <angular-editor-fabric-js #canvas></angular-editor-fabric-js>
            <div class="card">
                <!-- <div class="card-header">Façades</div> -->
                <div class="card-body  max-height-f">
                    <div class="img-div" *ngFor="let f of colors[nowColor.color_pos]?.facades">
                        <img class="images-item" (click)="changeFacade(f)" [src]="f.image">
                        <h6>{{f.name}} </h6>
                    </div>
                </div>
            </div>
            <br>
        </div>
    </div>
    <br>
    <br>
</div>