import { Component, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { FabricjsEditorComponent } from 'projects/angular-editor-fabric-js/src/public-api';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent {
  @ViewChild('canvas', { static: false }) canvas: FabricjsEditorComponent;
  data: any = {};
  colors: any[] = [];
  images: any[] = [];
  current = 0;
  nowFacade_id: any;
  nowColor: any = {
    color_id: '',
    color_pos: 0,
    facades: []
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private server: ApiService,
    private activatedRoute: ActivatedRoute,
  ) { }

  switchTo(tab){
  this.current = tab;
  }

  async getData(produit, user) {
    const data: any = {
      produit_id: produit,
      user_id: user
    }
    this.server.getData(data).subscribe(
      res => {
        this.data = res;
        console.log("this.data server.getData",this.data);
        
        this.getColors(this.data);
      },
      async error => {
        console.log(error);
      })
  }

  createNowFacade() {
    const nowFacade: any = {
      facade_id: this.nowFacade_id,
      facade_json: this.saveFacadeJson(),
      facade_svg: this.saveFacadeSVG()
    };
    return nowFacade;
  }

  saveNowFacade(nowFacade) {
    const i = this.posInFacadesArray(nowFacade.facade_id);
    if (i === -1) {
      this.nowColor.facades.push(nowFacade);
    } else {
      this.nowColor.facades.splice(i, 1);
      this.nowColor.facades.push(nowFacade);
    }
  }

  loadNowFacade(f) {
    const j = this.posInFacadesArray(f.id);
    if (j === -1) {
      this.clear();
      this.setCanvasImage(f.image);
    } else {
      const jsonFacade = this.nowColor.facades[j].facade_json;
      this.loadFacadeJson(jsonFacade);
    }
  }


  //sauvegarder l'image
  async sendData() {
    // create nowfacade
    const nowFacade = this.createNowFacade();

    // save nowfacade inarray
    this.saveNowFacade(nowFacade);

    this.images = [];
    console.log("this.nowColor",this.nowColor);
    for (const f of this.nowColor.facades) {
      this.images.push(f.facade_svg);
    }


    console.log("this.this.nowColor.facades)",this.nowColor.facades);
    const data: any = {
      produit_id: this.data.produit_id,
      user_id: this.data.user_id,
      images: this.images,
      //hadjer add color
      color:this.nowColor.facades
    }
    console.log('sendData',data);
    this.server.sendtData(data).subscribe(
      res => {
        console.log(res);
        window.location.href = this.data.url_retour;
      },
      async error => {
        console.log(error);
      })
  }

  ngAfterViewInit() {
    // this.getColors(this.data);
    this.activatedRoute.paramMap.subscribe(params => {
      let produit_id = params.get('produit');
      let user_id = params.get('user');
      this.getData(produit_id, user_id);
    });

  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getColors(data) {
    for (const c of data.produit.couleurs) {
      const color: any = {
        color_id: c.id,
        color_name: c.couleur,
        facade_id: c.facades[0].id,
        facade_name: c.facades[0].name,
        facade_image: c.facades[0].image,
        facades: c.facades
      };
      this.colors.push(color);
    }
    this.setCanvasImage(this.colors[0].facade_image);
    this.nowColor.color_id = this.colors[0].color_id;
    this.nowFacade_id = this.colors[0].facade_id;
  }

  changeColor(c, pos) {
    if (confirm('Vous allez perdre toute progression, voules vous continuer ?')) {
      this.clear();
      this.setCanvasImage(c.facade_image);
      this.nowColor.color_id = c.color_id;
      this.nowColor.color_pos = pos;
      this.nowFacade_id = c.facade_id;
      this.nowColor.facades = [];
    }
  }

  changeFacade(f) {
    // create nowfacade
    const nowFacade = this.createNowFacade();

    // save nowfacade inarray
    this.saveNowFacade(nowFacade);

    // load facae image
    this.loadNowFacade(f);


    // updating nowfacade
    this.nowFacade_id = f.id;
  }

  posInFacadesArray(facadeId) {
    return this.nowColor.facades.findIndex(f => f.facade_id === facadeId);
  }

  public saveFacadeJson() {
    return this.canvas.saveFacadeJson();
  }

  public saveFacadeSVG() {
    return this.canvas.saveFacadeSVG();
  }

  public loadFacadeJson(jsonFacade) {
    this.canvas.loadFacadeJson(jsonFacade);
  }

  public rasterize() {
    this.canvas.rasterize();
  }

  public rasterizeSVG() {
    this.canvas.rasterizeSVG();
  }

  public saveCanvasToJSON() {
    this.canvas.saveCanvasToJSON();
  }

  public loadCanvasFromJSON() {
    this.canvas.loadCanvasFromJSON();
  }

  public confirmClear() {
    this.canvas.confirmClear();
  }

  public clear() {
    this.canvas.clear();
  }

  public changeSize() {
    this.canvas.changeSize();
  }

  public addText() {
    this.canvas.addText();
  }

  public getImgPolaroid(event) {
    this.canvas.getImgPolaroid(event);
  }

  public addImageOnCanvas(url) {
    this.canvas.addImageOnCanvas(url);
  }

  public readUrl(event) {
    this.canvas.readUrl(event);
  }

  public removeWhite(url) {
    this.canvas.removeWhite(url);
  }

  public addFigure(figure) {
    this.canvas.addFigure(figure);
  }

  public removeSelected() {
    this.canvas.removeSelected();
  }

  public sendToBack() {
    this.canvas.sendToBack();
  }

  public bringToFront() {
    this.canvas.bringToFront();
  }

  public clone() {
    this.canvas.clone();
  }

  public cleanSelect() {
    this.canvas.cleanSelect();
  }

  public setCanvasFill() {
    this.canvas.setCanvasFill();
  }

  public setCanvasImage(imgUrl?) {
    if (imgUrl) { this.canvas.props.canvasImage = imgUrl; }
    this.canvas.setCanvasImage();
  }

  public setId() {
    this.canvas.setId();
  }

  public setOpacity() {
    this.canvas.setOpacity();
  }

  public setFill() {
    this.canvas.setFill();
  }

  public setFontFamily() {
    this.canvas.setFontFamily();
  }

  public setTextAlign(value) {
    this.canvas.setTextAlign(value);
  }

  public setBold() {
    this.canvas.setBold();
  }

  public setFontStyle() {
    this.canvas.setFontStyle();
  }

  public hasTextDecoration(value) {
    this.canvas.hasTextDecoration(value);
  }

  public setTextDecoration(value) {
    this.canvas.setTextDecoration(value);
  }

  public setFontSize() {
    this.canvas.setFontSize();
  }

  public setLineHeight() {
    this.canvas.setLineHeight();
  }

  public setCharSpacing() {
    this.canvas.setCharSpacing();
  }

  public rasterizeJSON() {
    this.canvas.rasterizeJSON();
  }


}
